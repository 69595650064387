export default {
  colors: {
    primary: "#2cf5dd",
    background: "#f7f7f7",
    sidebar: {
      background: "#ffffff",
      link: "#999",
      heading: "#aaa",
      linkActive: "#13131A",
      itemActive: "#F5F5FA",
      footer: "#A8A8B3",
    },
    unit: {
      background: "#ffffff",
    },
  },
};
